import { useEffect, useState, useRef } from "react"
import axios from "axios"
import { 
    DataTable,
    DataTableSkeleton,
    Table,
    TableHead,
    TableRow,
    TableHeader,
    TableBody,
    TableCell,
    TableToolbar,
    TableToolbarSearch,
    TableBatchActions,
    TableBatchAction,
    TableSelectAll,
    TableSelectRow,
    TableContainer,
    TableToolbarContent,
    Button,
    Pagination,
    Stack, 
    Section, 
    Tabs,
    Tab,
    TabList,
    Select,
    SelectItem,
    Modal,
    InlineLoading,
} from '@carbon/react';
import { TrashCan, Link, Upload, Add, Image, Document as DocumentIcon } from '@carbon/icons-react';

import { FormBuilder } from './FormBuilder'

// utils
import { updateObjectById, flattenArrayOfObjects, deepEqual } from '../utils/objects'
import { checkActionPermissionContext } from '../utils/permissions'

// exporters
import { parseHtmlFormsToJson } from '../utils/exporter/htmlToJson'
import { pdfExporter } from '../utils/exporter/pdf'
import { docExporter } from '../utils/exporter/docx'
import { xlsExporter } from '../utils/exporter/xls'
import { csvExporter } from '../utils/exporter/csv'
import { printExporter } from '../utils/exporter/printer'
import { txtExport } from '../utils/exporter/txt'
import { htmlExporter } from '../utils/exporter/html'

// exporter
const exportFormats = ['PRINT', 'DOC', 'PDF', 'XLS', 'CSV', 'TXT', 'HTML']

export const OpMode = {
    read: 0,
    edit: 1,
    create: 2,
    cancel: 3,
    standby: 4
}

/* import PropTypes from 'prop-types';

export const Button = ({ onClick, label }) => (
  <button type="button" onClick={onClick}>
    {label}
  </button>
);

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
}; */

const getPageData = (data, currentPage, pageSize) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return data.slice(startIndex, endIndex);
}

export const DataCRUD = ({ 
    canAdd=true, 
    canChange=true,
    canDelete=false,
    canCallActions=true,
    canExport=true,
    canBatchSelection=true,
    
    forceReload=0,
    headers, 
    title, 
    desc, 
    searchBy=null, // array of string keys to search for
    filters=null, 
    filterParams=null,

    form=[], 
    formTitleKey="", 
    formTitle="", 
    formTitleFunc=null,
    formData=null, 
    formActions=null, 
    formAutoRefresh=null,
    setApi, 
    setApiParam=null, 
    onSave=null, 
    onCreate=null, 
    onCancel=null, 
    onFormDisplay=null,

    gridData=null, 

    flatGridData=false,
    gridDataKey=null,

    gridActions=null, 
    onGridAction=null,

    gridAttachments=null,

    onAction=null,
    permissions=[],

    getApi=null, 
    getApiParam=null, 
    getApiFlatData=false,
    getApiKey=null, 
    getApiProcessData=null, // callback for external/parent data transformation before lod it in DataCRUD
    onExportDataReady=null,

    onDelete=null, 

    onFilterChange=null,
    onUpdateDataGrid=null,
    onAddRequest=null,

    exportMode=false,
    onExportedData=null,
    exportLoadDelay=2,

    spacerFixer=true,
    noScroll=false,
    localGridData=false // for local grid data handling, no paging, local search and ordering
}) => { 
    // datagrid
    const [loading, setLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [searchQuery, setSearchQuery] = useState('')
    const [ doSearch, setDoSearch ] = useState(false)
    const [pageSize, setPageSize] = useState(10)
    const [totalItems, setTotalItems] = useState(0)
    const [ordering, setOrdering] = useState('')
    const [data, setData] = useState([])
    const [dataBuffer, setDataBuffer] = useState([])
    const [visibleData, setVisibleData] = useState([])
    const [ searchTimer, setSearchTimer ] = useState(undefined)
    //const [gridActionList, setGridActionList] = useState([])
    // kinda of buffer for edition and restore function
    const [ selectedData, setSelectedData ] = useState(null)
    // filtering section
    const [ filter, setFilter ] = useState(0)
    const [ filterQuery, setFilterQuery ] = useState(" ")
    // exporter
    const [ exporterRenderer, setExporterRenderer ] = useState(null)
    const [ exportFormatSelectModal, setExportFormatSelectModal ] = useState(false)
    const [ exportFormat, setExportFormat ] = useState(null)
    const [ loadingExports, setLoadingExports ] = useState(false)
    const exporterRendererRef = useRef(null)
    const [counter, setCounter] = useState(exportLoadDelay);
    const timerRef = useRef(null);

    // reference for state comparison
    const prevSearchQueryRef = useRef(null)
    const prevFilterRef = useRef(null)
    const prevFilterQueryRef = useRef(null)
    const prevDataRef = useRef(null)
    const prevCurrentPageRef = useRef(null)
    const prevPageSizeRef = useRef(null)
    const prevOrderingRef = useRef(null)
    const prevForceReload = useRef(-1)
    const prevGetApiParam = useRef(-1)
    const prevGridData = useRef(-1)
    const prevExporterRenderer = useRef(-1)
    const formRef = useRef(null)
    const gridRef = useRef(null)

    const onSaveForm = (dataResponse) => {
        // re-render grid data?
        // update grid with new data
        setData(updateObjectById(data, dataResponse, dataResponse?.id))
        setSelectedData(dataResponse)
        onSave?.(dataResponse)
        if (noScroll === false)
            scrollToGrid()
    }

    const onCreateForm = (dataResponse) => {
        // re-render grid data?
        setData([dataResponse, ...data])
        setSelectedData(dataResponse)
        onCreate?.(dataResponse)
    }

    const generateExportData = (exportList) => {
        //if (!exportList) return
        
        const dataExport = []

        // retrieving full data based on id list of gridActionList
        exportList.forEach((e, idx) => {
            const current = data.find(obj => obj.id === e.id)
            // generate and fill managed data
            //const newFormData = generateData(form, { ...current, ...formData})
            const newFormData = { ...current, ...formData}
            dataExport.push(newFormData)
        })

        // set loading state for exporter
        setLoadingExports(true)
        setExporterRenderer(
            <Section>
                {dataExport.map((e, idx) => 
                    <Section level={2} style={{ marginBottom: '45px' }}>
                        <FormBuilder 
                            key={idx}
                            form={form}
                            formData={e}
                            formTitleKey={formTitleKey}
                            formTitle={formTitle}
                            formTitleFunc={formTitleFunc}
                            formActions={formActions} 
                            apiFlatData={getApiFlatData}
                            exportRender={true}
                            filter={filter}
                            onAction={onActionLocal}
                            exportMode={exportMode}
                        />
                    </Section>
                )}
            </Section>
        )
    }
    
    // generic handler exporter to prepare and pre parse data based on our app views
    // the after modal and export selection
    const exportData = () => {
        // prepare and render base data
        // set data list ready 
        //setGridActionList(actionList)
        console.log("exporting...")
        // our hidden html processed export data
        const exportedHtmlData = exporterRendererRef.current.innerHTML

        switch (exportFormat) {
            case 'PDF':
                pdfExporter(exportedHtmlData)
            break
            case 'HTML':
                htmlExporter(exportedHtmlData)
            break
            case 'PRINT':
                printExporter(exportedHtmlData)
            break
            case 'DOC':
                docExporter(exportedHtmlData)
            break
            case 'XLS':
                xlsExporter(exportedHtmlData)
            break
            case 'CSV':
                csvExporter(exportedHtmlData)
            break
            case 'TXT':
                txtExport(exportedHtmlData)
            break
            default:

            break
        }

        // close modal...
        setExportFormatSelectModal(false)
        // force exportFormat to null to force re-processing on new request
        setExportFormat(null)

        onExportedData?.(exportFormat)
    }

    // when user clicks batch action on grid
    const handleGridBatchActions = (e, actionList) => {
        e.preventDefault()
        
        switch (e.target.id) {
            case "deleteGrid":
                if (onDelete)
                    onDelete(actionList)
                //console.log("deleting...")
            break

            /* case "printGrid":
                console.log("printing...")
                setExportList(actionList)
                setExportFormat('PRINT')
                exportData()
            break */
            
            case "exportGrid":
                console.log('prepare to exporting...')
                //setExportList(actionList)
                // generate all data based on our formbuilder layout hidden at bottom of DataCRUD
                generateExportData(actionList)
            break
            default:

            break
        }
    }

    // pagination watcher
    const handlePaginantion = (e) => {
        setPageSize(e.pageSize)
        setCurrentPage(e.page)
    }
    
    const onSelectData = (id) => {
        // check if we are in create mode...
        const selected = data.find(obj => obj.id === id)
        // generate and fill managed data
        //const newFormData = generateData(form, { ...selected, ...formData})
        const newFormData = { ...selected, ...formData}
        if (form.length > 0) {
            // fill buffer for restore
            setSelectedData(selected)
            //setManagerData(newFormData)
            //setEditMode(OpMode.read)
            // scroll down to data
            //navigate("#dataInfo")
        }
        // external change form state display
        onFormDisplay?.(newFormData, OpMode.read)
        // scroll to content smooothly
        if (noScroll === false)
            scrollToForm()
    }

    const onActionLocal = (action, actionData) => {
        //scrollToGrid()
        onAction?.(action, actionData)
    }

    const scrollToForm = () => {
        // wait 0.3 seconds for form to load up
        setTimeout(() => {
            // scroll to content smoothly 
            window.scrollTo({
                top: formRef.current.offsetTop - 70,
                behavior: 'smooth'
            });
        }, 100);
    }

    const scrollToGrid = () => {
        // scroll to content smooothly
        window.scrollTo({
            top: gridRef.current.offsetTop - 70,
            behavior: 'smooth'
          });
    }

    // search and filters callback
    const onSetFilter = (filterKey) => {
        setFilter(filterKey)
        if (noScroll === false)
            scrollToGrid()
    }
    
    const onSearchGrid = (e) => {
        const inputValue = e.target.value
        setSearchQuery(inputValue)

        // do we need to reset the search?
        if (inputValue === "") {
            // always force page 1 at search query
            setCurrentPage(1)
            setDoSearch(true)
            return
        }

        // if there is any timer already set, reset the counter timeout
        if (searchTimer) 
            clearTimeout(searchTimer);

        // No timer setup? Start a 300ms timer and call onSearchProduct when the timer ends
        setSearchTimer(setTimeout(async () => {
            // always force page 1 at search query
            setCurrentPage(1)
            setDoSearch(true)
        }, 300))
    }

    // button create
    const onCreateAction = () => {
        // set empty object to get create form
        setSelectedData({})
        //setManagerData(null)
        //setEditMode(OpMode.create)
        //if (onFormDisplay)
        //    onFormDisplay({})
        if (noScroll === false)
            scrollToForm()
    }

    const onCancelForm = (currentEditMode) => {
        //if (currentEditMode === OpMode.create)
        if (noScroll === false)
            scrollToGrid()
        onCancel?.()
    }

    const onMediaClick = (url) => {
        window.open(url, '_blank');
    }

    // use it on localmemory too for total ordering instead of visiable elements only
    const onOrderingData = (headerData) => {
        //console.log(headerData)
        const orderByKey = headerData?.key?.endsWith('_code') ? headerData.key.slice(0, -5) : headerData.key
        const orderingParam = ordering.includes(orderByKey) ? (ordering[0] === '-' ? orderByKey : `-${orderByKey}`) : orderByKey
        setOrdering(orderingParam)
        //if (getApi !== null)
        //    fetchData()
    }

    const fetchData = async () => {
        let response

        // unload any formdata when refresh
        setSelectedData(null)

        // set loading skeleton until the job is done
        if (!doSearch && searchQuery === "") {
            setLoading(true)
            setSearchQuery("")
        }

        // backend query params        
        const filterData = filters && filterQuery === " " ? getFilterQuery(filter) : filterQuery
        const searchData = searchBy && searchQuery === '' ? null : searchBy?.reduce((acc, val, index) => {
            // Check if it's the last element
            const isLast = index === searchBy.length - 1;
            // Append the string with comma if it's not the last element
            return acc + `${val}:${searchQuery}${isLast ? '' : ','}`;
        }, '');

        const dataParams = exportMode ? 
            filterParams ? { params: filterParams } : {} 
            : {
                params: {
                    page: currentPage,
                    page_size: pageSize,
                    ordering: ordering,
                    filter: filterData,
                    search: searchData,
                }
            }
        
        if (getApiParam) {
            response = await getApi?.({ ...dataParams, ...getApiParam })
        } else {
            response = await getApi?.(dataParams)
        }
        
        // is this a backend managable query/pagination schema?
        if (response?.results) {
            setTotalItems(response?.count)
            //setBackendPagination(true)
            response = response.results
        }

        if (response.length === 0 && exportMode) {
            // inform user that no results found
            onExportDataReady?.(response.length);
        }

        // do we need to transform this data?
        if (getApiProcessData !== null) 
            response = getApiProcessData(response, filters?.[filter], exportMode)
        
        // for actions query on non array structures that uses getApiKey
        setDataBuffer(response)

        if (getApiKey)
            response = response?.[getApiKey]

        if (getApiFlatData && response)
            response = flattenArrayOfObjects(response)
        
        if (Array.isArray(response))
            setData(response)

        // loading end
        if (!doSearch && searchQuery === "")
            setLoading(false)

        //setDoSearch(false)
        //onDataLoaded?.()
    }

    const handleDataBuild = () => {
        if (getApi !== null) {
            fetchData()
        } else if (gridData !== null) {
            // for actions query on non array structures that uses getApiKey
            let gridDataHelper = gridData

            setDataBuffer(gridDataHelper)

            if (gridDataKey)
                gridDataHelper = gridDataHelper[gridDataKey]

            if (flatGridData)
                setData(flattenArrayOfObjects(gridDataHelper))
            else
                setData(gridDataHelper)
        } else if (formData !== null) {
            //const newFormData = generateData(form, formData)
            //setManagerData(newFormData)
            setSelectedData(formData)
            //setManagerData(formData)
            //setEditMode(OpMode.read)
            //if (onFormDisplay)
            //    onFormDisplay(newFormData)
        }
    }

    // query data related
    // onInit
    // on forceReload
    // on getApiParam changes
    // on gridData changes
    useEffect(() => {
        handleDataBuild()
    }, [])

    // listening to data requests to counting and check end of requests series
    useEffect(() => {
        const resetCounter = () => {
          setCounter(exportLoadDelay);
          if (timerRef.current) {
            clearInterval(timerRef.current);
          }
        };
    
        const startTimer = () => {
          timerRef.current = setInterval(() => {
            setCounter((prevCounter) => {
              if (prevCounter > 0) {
                return prevCounter - 1;
              } else {
                onExportDataReady?.(data.length);
                setLoadingExports(false);
                setExportFormatSelectModal(true);
                resetCounter();
                return 0;
              }
            });
          }, 1000);
        };
    
        if (!deepEqual(prevExporterRenderer.current, exporterRenderer) && loadingExports) {
        //if (exporterRenderer && loadingExports) {
          const requestInterceptor = axios.interceptors.request.use(
            (config) => {
              resetCounter();
              startTimer();
              return config;
            },
            (error) => {
              return Promise.reject(error);
            }
          );
    
          /* const responseInterceptor = axios.interceptors.response.use(
            (response) => {
              resetCounter();
              startTimer();
              return response;
            },
            (error) => {
              return Promise.reject(error);
            }
          ); */
    
          resetCounter();
          startTimer();
    
          return () => {
            axios.interceptors.request.eject(requestInterceptor);
            //axios.interceptors.response.eject(responseInterceptor);
            resetCounter();
          };
        }
        prevExporterRenderer.current = exporterRenderer;

      }, [exporterRenderer, onExportDataReady, setLoadingExports, setExportFormatSelectModal]);
         
    // we need a efectivy way of know when renders stops
    /* useEffect(() => {
        if (exporterRenderer) {
            const timer = setTimeout(() => {
                onExportDataReady?.()
                setLoadingExports(false)
                // open modal asking user which format to export
                setExportFormatSelectModal(true);
            }, exportLoadDelay * 1000); 
            return () => clearTimeout(timer); // Cleanup timer
        }
    }, [exporterRenderer]); */

    useEffect(() => {
        // Skip the first render
        if (prevForceReload.current !== -1 && forceReload !== 0) {
            // clear form state if its forceReload
            //setManagerData(null)
            //setEditMode(OpMode.standby)
            setSelectedData(null)
            // external change form state display
            onFormDisplay?.(null, OpMode.standby)
            handleDataBuild()
            if (noScroll === false)
                scrollToGrid()
        }
        prevForceReload.current = forceReload;
    }, [forceReload])

    useEffect(() => {
        // Skip the first render
        if (prevGetApiParam.current !== -1 && (!deepEqual(prevGetApiParam.current, getApiParam))) {
            handleDataBuild()
        }
        prevGetApiParam.current = getApiParam;
    }, [getApiParam])

    useEffect(() => {
        // Skip the first render
        if (prevGridData.current !== -1) {
            handleDataBuild()
        }
        prevGridData.current = gridData;
    }, [gridData])

    const handleInMemoryDataGridUX = () => {
        // Calculate visible rows based on page, search query and filtering
        let dataHandler
        if (searchQuery !== '' && searchBy) {
            //prevSearchQueryRef.current = prevSearchQueryRef.current
            dataHandler = data.filter((item) => {
                for (const prop of searchBy) {
                    if (item[prop]?.toString().toLowerCase().includes(searchQuery.toLowerCase())) {
                        return true
                    }
                }
                return false
            })
            // set first page on each search initial type until stop typing to navigate thru
            if (searchQuery !== prevSearchQueryRef.current && localGridData !== true) {
                setCurrentPage(1)
            }
            prevSearchQueryRef.current = searchQuery;
        } else {
            dataHandler = data
        }

        // any filtering to apply
        if (filters && filters[filter] && filters[filter].query) {
            // Apply filter based on the selected filter's query
            dataHandler = dataHandler.filter((item) =>
                filters[filter].query.every((condition) => {
                    const itemValue = item?.[condition.key]

                    if (Array.isArray(condition.value)) {
                        // Check if any value in the array matches the item's value
                        return itemValue !== null ? condition.value.includes(itemValue) : true
                    } else {
                        // Check for a single value
                        return itemValue !== null ? itemValue === condition.value : true
                    }
                })
            )
        }
        /* if (filters && filters[filter] && filters[filter].query) {
            // Apply filter based on the selected filter's query
            dataHandler = dataHandler.filter((item) =>
                //filters[filter].query.every((condition) => item[condition.key] === condition.value)
                filters[filter].query.every((condition) => item?.[condition.key] !== null ? item[condition.key] === condition.value : true)
            )
        } */

        // we need to filter page data on memory based schema
        if (localGridData !== true)
            setVisibleData(getPageData(dataHandler, currentPage, pageSize))
        else
            setVisibleData(dataHandler)

        setTotalItems(dataHandler.length)

        /* // filter changes should close form
        if (prevFilterRef.current !== null && prevFilterRef.current !== filter) {
            //setManagerData(null)
            //setEditMode(OpMode.standby)
            setSelectedData(null)
            if (onFilterChange !== null) {
                onFilterChange(filter)
                onFormDisplay?.(null, OpMode.standby)
            }
        }
        // Update the previous filter value
        prevFilterRef.current = filter; */

        // should we call onUpdateDataGrid?
        if (prevDataRef.current !== null && prevDataRef.current !== data) {
            if (onUpdateDataGrid)
                onUpdateDataGrid(data)
        }
        // Update the previous filter value
        prevDataRef.current = data;     
    }

    const getFilterQuery = (filterId) => {
        let queryString = ''
        if (filters?.[filter]?.query) {
            // Apply filter based on the selected filter's query
            const query = filters[filter].query;
            queryString = query.map(item => `${item.key}:${item.value}`).join(',');
        }
        return queryString
    }

    useEffect(() => {
        // filtering to apply
        setFilterQuery(getFilterQuery(filter))
        // filter changes should close form
        if (prevFilterRef.current !== null && prevFilterRef.current !== filter) {
            //setManagerData(null)
            //setEditMode(OpMode.standby)
            setSelectedData(null)
            if (onFilterChange !== null) {
                onFilterChange(filter)
                onFormDisplay?.(null, OpMode.standby)
            }
        }
        // Update the previous filter value
        prevFilterRef.current = filter;
    }, [filter])

    useEffect(() => {
        if (localGridData) {
            handleInMemoryDataGridUX()
        }
    }, [searchQuery])

    const handleBackendDataGridUX = () => {
        if (
            prevCurrentPageRef.current !== null && prevCurrentPageRef.current !== currentPage ||
            prevPageSizeRef.current !== null && prevPageSizeRef.current !== pageSize ||
            prevOrderingRef.current !== null && prevOrderingRef.current !== ordering ||
            (prevFilterQueryRef.current !== null && prevFilterQueryRef.current !== filterQuery && prevFilterQueryRef.current !== " ")
        ) {
            fetchData()
        }
        // Update the previous currentPage value
        prevCurrentPageRef.current = currentPage;
        // Update the previous pageSize value
        prevPageSizeRef.current = pageSize;
        prevOrderingRef.current = ordering;
        prevFilterQueryRef.current = filterQuery;

        setVisibleData(data)

        // should we call onUpdateDataGrid?
        if (prevDataRef.current !== null && prevDataRef.current !== data) {
            if (onUpdateDataGrid)
                onUpdateDataGrid(data)
        }
        // Update the previous filter value
        prevDataRef.current = data;     
    }

    // state variables that trigers datagrid changes
    useEffect(() => {
        // only goes thru this handle if we are using fetchApi
        
        // if local memory mode search and paging...
        if (localGridData) {
            handleInMemoryDataGridUX()
        } else if (getApi !== null) {
            handleBackendDataGridUX()
        }

        // are we in export mode?
        if (exportMode === true && data.length > 0) {
            //setExportList(data.map(e => { return { id: e.id } }))
            generateExportData(data.map(e => { return { id: e.id } }))
        }
    }, [data, filterQuery, ordering, currentPage, pageSize])
    // searchQuery handle on enter for searching

    useEffect(() => {
        if (doSearch && getApi !== null && localGridData === false) {
            fetchData()
            setDoSearch(false)
        }
    }, [doSearch])

    return (
        <Stack ref={gridRef} gap={8}>
            {exportFormatSelectModal &&
                <Modal 
                    open={exportFormatSelectModal}
                    modalHeading="Exportar Lista"
                    primaryButtonText="Exportar" 
                    secondaryButtonText="Cancelar" 
                    onRequestSubmit={exportData} 
                    onRequestClose={() => setExportFormatSelectModal(false)}
                >
                    <Select
                        id="exporter-format-select"
                        name="exporter-format-select"
                        labelText="Selecione o formato de exportação:"
                        onChange={e => setExportFormat(e.target.value)}
                        value={exportFormat}
                    >
                        <SelectItem key="011" value="" text="Selecione um formato" />
                        {exportFormats?.map((e, idx) => 
                            <SelectItem key={idx} value={e} text={e === 'PRINT' ? 'IMPRIMIR' : e} />
                        )}
                    </Select>                  
                </Modal>
            }
            {(filters && !exportMode) &&
                <Section>
                    <Tabs selectedIndex={filter} onChange={(e) => onSetFilter(e.selectedIndex)}>
                        <TabList aria-label="Filtros">
                            {filters.map((f, idx) => <Tab key={idx}>{f.label}</Tab>)}
                        </TabList>
                    </Tabs>
                </Section>
            }
            {(loading  && !exportMode) ?
                <DataTableSkeleton headers={headers} />
                :
                <>
                {((getApi || gridData) && !exportMode)  &&
                <Section>
                    <DataTable rows={visibleData} headers={headers} isSortable={localGridData}>
                        {({
                            rows,
                            headers,
                            getHeaderProps,
                            getRowProps,
                            getSelectionProps,
                            getToolbarProps,
                            getBatchActionProps,
                            onInputChange,
                            selectedRows,
                            getTableProps,
                            getTableContainerProps,
                            selectRow
                        }) => {
                            const batchActionProps = {
                                ...getBatchActionProps({
                                    onSelectAll: () => {
                                    rows.map(row => {
                                        if (!row.isSelected) {
                                            selectRow(row.id)
                                        }
                                    });
                                    }
                                })
                            }
                            return (
                                <TableContainer title={title} description={desc} {...getTableContainerProps()}>
                                    <TableToolbar {...getToolbarProps()}>
                                        <TableBatchActions {...batchActionProps}>
                                            {canDelete &&
                                                <TableBatchAction id="deleteGrid" tabIndex={batchActionProps.shouldShowBatchActions ? 0 : -1} renderIcon={TrashCan} onClick={(e) => handleGridBatchActions(e, selectedRows)}>
                                                    Remover
                                                </TableBatchAction>
                                            }
                                            {/* <TableBatchAction id="printGrid" tabIndex={batchActionProps.shouldShowBatchActions ? 0 : -1} renderIcon={Printer} onClick={(e) => handleGridBatchActions(e, selectedRows)} hasIconOnly>
                                                Imprimir
                                            </TableBatchAction> */}
                                            {canExport &&
                                                <TableBatchAction id="exportGrid" tabIndex={batchActionProps.shouldShowBatchActions ? 0 : -1} renderIcon={loadingExports ? InlineLoading : Upload} onClick={(e) => handleGridBatchActions(e, selectedRows)}>
                                                   { loadingExports ? 'Exportando... Aguarde' : 'Exportar Lista' }
                                                </TableBatchAction>
                                            }
                                        </TableBatchActions>
                                        <TableToolbarContent aria-hidden={batchActionProps.shouldShowBatchActions}>
                                            <TableToolbarSearch 
                                                tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0} 
                                                onChange={onSearchGrid} 
                                            />
                                            {gridAttachments && 
                                                gridAttachments.map((e, idx) => {
                                                    if (e?.doc) 
                                                        return <DocumentIcon alt={e.doc} key={idx} onClick={() => onMediaClick(e.doc)} style={{ margin: '15px', cursor: 'pointer' }} />
                                                    else if (e?.image)
                                                        return <Image alt={e.image} key={idx} onClick={() => onMediaClick(e.image)} style={{ margin: '15px', cursor: 'pointer' }} />
                                                    else if (e?.url)
                                                        return <Link alt={e.url} key={idx} onClick={() => onMediaClick(e.url)} style={{ margin: '15px', cursor: 'pointer' }} />
                                                })   
                                            }
                                            {gridActions &&
                                                Object.entries(gridActions)
                                                    .filter(([, actionData]) =>
                                                        Object.entries(actionData.context).every(([key, value]) => {
                                                            const dataValue = dataBuffer?.[key];
                                                            
                                                            if (Array.isArray(value)) {
                                                                // Check if the managerData value is in the array
                                                                return dataValue !== undefined && value.includes(dataValue);
                                                            } else {
                                                                // Check if the managerData value matches the single value
                                                                return dataValue !== undefined && dataValue === value;
                                                            }
                                                        })
                                                    )
                                                    .map(([actionName, actionData]) => (
                                                        <Button
                                                            disabled={actionData.disabled || !canCallActions ? true : false}
                                                            onClick={() => onGridAction?.(actionName, dataBuffer['id'])}
                                                            renderIcon={actionData?.icon}
                                                            iconDescription=""
                                                            kind={actionData?.kind ?? "primary"}
                                                        >
                                                            {actionData.label}
                                                        </Button>
                                                    ))
                                            }
                                            {((form.length > 0 || onAddRequest) && canAdd) && (
                                                formActions?.add ? 
                                                    <Button disabled={!checkActionPermissionContext(formActions.add, permissions, dataBuffer)} renderIcon={formActions?.add?.icon ?? Add} tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0} onClick={() => { return onAddRequest ? onAddRequest() : onCreateAction() }} kind="primary">
                                                        {formActions?.add?.label ?? "Adicionar"}
                                                    </Button>
                                                :
                                                    <Button renderIcon={Add} tabIndex={batchActionProps.shouldShowBatchActions ? -1 : 0} onClick={() => { return onAddRequest ? onAddRequest() : onCreateAction() }} kind="primary">
                                                        {'Adicionar'}
                                                    </Button>
                                            )}
                                        </TableToolbarContent>
                                    </TableToolbar>
                                    <Table {...getTableProps()} aria-label="data-table">
                                        <TableHead>
                                            <TableRow>
                                                {canBatchSelection && <TableSelectAll {...getSelectionProps()} />}
                                                {headers.map((header, i) => 
                                                        <TableHeader key={i} {...getHeaderProps({ header })}>
                                                            <div style={{ cursor: 'pointer' }} onClick={e => onOrderingData(header)} >
                                                                {header.header}
                                                            </div>
                                                        </TableHeader>
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row, i) => 
                                                <TableRow style={{ cursor: 'pointer' }} key={i} {...getRowProps({ row })}>
                                                    {canBatchSelection && <TableSelectRow {...getSelectionProps({ row })} />}
                                                    {row.cells.map((cell, idx) => 
                                                        <TableCell 
                                                            onClick={() => onSelectData(row.id)} 
                                                            key={idx}
                                                        >
                                                            {cell.value}
                                                        </TableCell>
                                                    )}
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            )
                        }}
                    </DataTable>
                    {localGridData !== true && 
                        <Pagination
                            backwardText="Anterior"
                            forwardText="Proxima"
                            itemsPerPageText="Itens por página:"
                            pageNumberText="Número da Página"
                            pageText={(page, pagesUnknown) => `página ${ pagesUnknown ? '' : page }`}
                            pageRangeText={(_current, total) => `de ${ total } ${ total === 1 ? 'página' : 'páginas' }`}
                            itemText={(min, max) => `${ min }–${ max } itens`}
                            itemRangeText={(min, max, total) => `${ min }–${ max } de ${ total } itens`}
                            page={currentPage}
                            pageSize={pageSize}
                            pageSizes={[
                                10,
                                20,
                                30,
                                40,
                                50
                            ]}
                            size="md"
                            totalItems={totalItems}
                            onChange={handlePaginantion}
                        />
                    }
                </Section>
            }
                </>
            }
            
            <Section ref={formRef}>
                {(selectedData || formData) &&
                    <FormBuilder 
                        form={form}
                        /* editMode={editMode} */
                        formData={{ ...selectedData, ...formData}}
                        formTitleKey={formTitleKey}
                        formTitle={formTitle}
                        formTitleFunc={formTitleFunc}
                        formActions={formActions} 
                        setApi={setApi}
                        setApiParam={setApiParam} 
                        onSave={onSaveForm}
                        onCreate={onCreateForm}
                        onCancel={onCancelForm} 
                        onFormDisplay={onFormDisplay}
                        getApi={getApi}
                        getApiParam={getApiParam}
                        formAutoRefresh={formAutoRefresh}
                        apiFlatData={getApiFlatData}
                        canAdd={canAdd} 
                        canChange={canChange}
                        canDelete={canDelete}
                        canCallActions={canCallActions}
                        onAction={onActionLocal}
                        permissions={permissions}
                        filter={filter}
                        exportMode={exportMode}
                    />
                }
            </Section>
            {(getApi !== null && spacerFixer) &&
                <Section style={{ minHeight: '480px' }} />
            }
            <Section ref={exporterRendererRef} style={{ display: 'none' }}>
                {exporterRenderer && exporterRenderer}
            </Section>
        </Stack>
    )
}