import { useState, useEffect } from "react";
import { 
    TextInput, 
    Heading, 
    Section, 
    FlexGrid, 
    Row, 
    Column, 
    ClickableTile,
    InlineLoading,
    DatePicker,
    DatePickerInput,
    Modal,
} from '@carbon/react';
import { UiShell } from '../containers/Template'

// icons
import { Download } from '@carbon/icons-react';

// api imports
import { generateReportApi, getLastReportApi, setReportLogApi } from '../utils/api/reports'
import { getEntitiesApi } from '../utils/api/entities'
import { getDebtsStats } from '../utils/api/balance'

// exporter helpers
import { ExporterBox } from '../containers/ExporterBox'

// datacrud setups meta data
import { ordersDataCRUD } from '../datacruds/compramed-orders'
import { publicEntitiesDataCRUD } from '../datacruds/publicEntities'
import { linkedPublicEntitiesDataCRUD } from '../datacruds/linkedPublicEntities'
import { operatorsDataCRUD } from '../datacruds/operators'
import { productsDataCRUD } from '../datacruds/products'
import { balanceContingencyDataCRUD } from '../datacruds/balanceContingency'

const EXPORT_TEMPLATE_FILENAMES = {
    "main_entities": "Entes",
    "linked_entities": "Entidades-Vinculadas",
    "operators": "Operadores-Autorizados",
    "products": "Produtos",
    "orders": "Solicitações-de-Compra",
    "orders_compraserv": "Solicitações-de-Compra",
    "balance": "Empenho-Orçamentário"
}

const Reports = () => {
    const today = Date.now()
    const [ exportFormatSelectModal, setExportFormatSelectModal ] = useState(false)
    const [ noDataFoundModal, setNoDataFoundModal ] = useState(0)
    const [ loading, setLoading ] = useState(false)
    const [ exportData, setExportData ] = useState(null)
    const [ exportTemplate, setExportTemplate ] = useState(null)
    const [ lastExportedData, setLastExportedData ] = useState(null)
    const [ exportFilterParams, setExportFilterParams ] = useState([today,today])
    const appMode = window.APP_MODE

    const exportReport = async (report) => {
        // if we are loading already do nothing until it ends
        if (loading)
            return

        // reportData json to be used by a carbona template
        const reportData = {
            "data": {
                "header": {
                    "domain": "",
                    "entity": {
                        "name": "",
                        "cnpj": ""
                    },
                    "report": {
                        "start_date": "",
                        "end_date": "",
                        "created_at": "",
                        "created_by": ""
                    },
                }
            }
            //"convertTo":"pdf"
        }

        let reportLog = null
        const startDate = getStartOfDay(exportFilterParams[0])
        const endDate = getEndOfDay(exportFilterParams[1])

        // enable loading state feedback
        setLoading(true)

        switch (report) {
            case "publicEntityExport":
                // set the report template
                setExportTemplate('main_entities')
                // retrieve report data
                const entities = await publicEntitiesDataCRUD.getApi({ params: { dateRange: `${startDate},${endDate}`}})
                if (entities.length > 0) {
                    // set action to exported on backend
                    reportLog = await setReportLogApi("Entes")
                    // set report main data
                    reportData["data"]["entities"] = entities
                    
                    reportData["data"]["results"] =  {
                        "debts": entities.reduce((v, e) => v+parseFloat(e.contract_outcome.replaceAll(".","").replaceAll(",",".")),0.0),
                        "balance": entities[0].contract_income,
                    }

                    // call modal to ask user output format of this report
                    setExportFormatSelectModal(true)
                } else {
                    // show user a modal informing about no data to export
                    setNoDataFoundModal(1)
                }
            break
            case "linkedPublicEntityExport":
                // set the report template
                setExportTemplate('linked_entities')
                // retrieve report data
                const main_entities = await publicEntitiesDataCRUD.getApi({ params: { dateRange: `${startDate},${endDate}`}})
                if (main_entities.length > 0) {
                    // set action to exported on backend
                    reportLog = await setReportLogApi("Entes")
                    // set report main data
                    reportData["data"]["main_entities"] = main_entities
                    reportData["data"]["results_entities"] =  {
                        "debts": main_entities.reduce((v, e) => v+parseFloat(e.contract_outcome.replaceAll(".","").replaceAll(",",".")),0.0),
                        "balance": main_entities[0].contract_income,
                    }

                    // retrieve report data
                    const linked_entities = await linkedPublicEntitiesDataCRUD.getApi({ params: { dateRange: `${startDate},${endDate}`}})
                    if (linked_entities.length > 0) {
                        // set action to exported on backend
                        reportLog = await setReportLogApi("Entidades Vinculadas")
                        // set report main data
                        reportData["data"]["linked_entities"] = linked_entities
                        reportData["data"]["results"] =  {
                            "debts": linked_entities.reduce((v, e) => v+parseFloat(e.contract_outcome.replaceAll(".","").replaceAll(",",".")),0.0)
                        }
                        
                        // call modal to ask user output format of this report
                        setExportFormatSelectModal(true)                
                    } else {
                        // show user a modal informing about no data to export
                        setNoDataFoundModal(1)
                    }
                } else {
                    // show user a modal informing about no data to export
                    setNoDataFoundModal(1)
                }
            break
            case "operatorsExport":
                // set the report template
                setExportTemplate('operators')
                // retrieve report data
                const operators = await operatorsDataCRUD.getApi({ params: { dateRange: `${startDate},${endDate}`}})
                if (operators.length > 0) {
                    // set action to exported on backend
                    reportLog = await setReportLogApi("Operadores Autorizados")
                    // set report main data
                    reportData["data"]["operators"] = operators
                    
                    reportData["data"]["results"] =  {
                        "managers": operators.filter(op => op.group_code === "Gestores").length,
                        "operators": operators.filter(op => op.group_code === "Operadores").length
                        //"fiscais": operators.filter(op => op.group_code === "Fiscais").length,
                        //"support": operators.filter(op => op.group_code === "Suporte").length
                    }

                    // call modal to ask user output format of this report
                    setExportFormatSelectModal(true)
                } else {
                    // show user a modal informing about no data to export
                    setNoDataFoundModal(1)
                }
                //setExportData(reportData)
                //setExportedData("Operadores Autorizados")
            break
            case "productsExport":
                // set the report template
                setExportTemplate('products')
                // retrieve report data
                const products = await productsDataCRUD.getApi({ params: { dateRange: `${startDate},${endDate}`}})
                if (products.length > 0) {
                    // set action to exported on backend
                    reportLog = await setReportLogApi("Produtos")
                    // set report main data
                    reportData["data"]["products"] = products
                    reportData["data"]["results"] =  {
                        "products_number": products.length
                    }

                    // call modal to ask user output format of this report
                    setExportFormatSelectModal(true)
                } else {
                    // show user a modal informing about no data to export
                    setNoDataFoundModal(1)
                }
            break
            case "ordersExport":
                // set the report template
                if (appMode === 'CompraMED') {
                    setExportTemplate('orders')
                } else if (appMode === 'CompraSERV') {
                    setExportTemplate('orders_compraserv')
                }
                // retrieve report data
                const orders = await ordersDataCRUD.getApi({ params: { dateRange: `${startDate},${endDate}`}})
                if (orders.length > 0) {
                    // set action to exported on backend
                    reportLog = await setReportLogApi("Solicitações de Compra")

                    // set report main data
                    reportData["data"]["orders"] = orders
                    reportData["data"]["product_orders"] = []
                    reportData["data"]["service_orders"] = []
                    
                    // we need to calculate the total of each item using total = quantity * price
                    // set detailed orders + quotations information
                    for (const [idx, order] of reportData.data.orders.entries()) {

                        // service or product order?
                        if (reportData.data.orders[idx].order_items.length > 0) {
                            // set total and aguardando values
                            reportData.data.orders[idx].order_items.forEach(item => {
                                if (item.status === 4) { // Redistribuido
                                    item.quote_price = "RDS"
                                    item.total = "RDS"
                                    item.quote_from = "RDS"
                                } else if (item.status === 5) { // Deserto
                                    item.quote_price = "Item Deserto"
                                    item.total = "Item Deserto"
                                    item.quote_from = "Item Deserto"
                                } else if (item.status === 0) { // Aguardando compra
                                    item.quote_price = "Aguardando"
                                    item.total = "Aguardando"
                                    item.quote_from = "Aguardando"
                                } else {
                                    item.total = item.quantity * parseFloat(item.quote_price)
                                }
                            })
                            reportData["data"]["product_orders"].push(order)
                        } else if (reportData.data.orders[idx].service_items.length > 0) {
                            // if service_items
                            reportData.data.orders[idx].service_items.forEach(item => {
                                if (item.status === 4) { // Redistribuido
                                    item.quote_price = "RDS"
                                    item.total = "RDS"
                                    item.quote_from = "RDS"
                                } else if (item.status === 5) { // Deserto
                                    item.quote_price = "Item Deserto"
                                    item.total = "Item Deserto"
                                    item.quote_from = "Item Deserto"
                                } else if (item.status === 0) { // Aguardando compra
                                    item.quote_price = "Aguardando"
                                    item.total = "Aguardando"
                                    item.quote_from = "Aguardando"
                                } else {
                                    item.total = item.quantity * parseFloat(item.quote_price)
                                }
                            })
                            reportData["data"]["service_orders"].push(order)
                        }

                    }
                    
                    // for amount values spend and locked we filter only those from buy order to received status
                    const processed_orders = orders.filter(e => e.status >= 3 && e.status <= 5)
                    reportData["data"]["results"] =  {
                        "total": processed_orders.reduce((v, e) => v+parseFloat(e.total.replaceAll(".","").replaceAll(",",".")),0.0),
                        "debited": processed_orders.reduce((v, e) => v+parseFloat(e.debited.replaceAll(".","").replaceAll(",",".")),0.0),
                        //"balance": balance[0].contract_income,
                    }

                    // call modal to ask user output format of this report
                    setExportFormatSelectModal(true)
                } else {
                    // show user a modal informing about no data to export
                    setNoDataFoundModal(1)
                }
                //setExportData(reportData)
                // we need to add filterBy param to ask from status date into array and agregate
                //setExportedData("Solicitações de Compra")
            break
            case "balanceExport":
                // set the report template
                setExportTemplate('balance')
                // retrieve balance data
                const balance = await balanceContingencyDataCRUD.getApi({ params: { dateRange: `${startDate},${endDate}`}})
                // retrieve debts data
                const debts = await getDebtsStats({ params: { dateRange: `${startDate},${endDate}`}})
                console.log(debts)
                if (balance.length > 0) {
                    // set action to exported on backend
                    reportLog = await setReportLogApi("Empenho Orçamentário")
                    // set report main data
                    reportData["data"]["balance"] = balance
                    
                    reportData["data"]["results"] =  {
                        "total": balance.filter(e => e.status > 0).reduce((v, e) => v+parseFloat(e.total.replaceAll(".","").replaceAll(",",".")),0.0),
                        "debts": debts.filter(e => e.status !== 2).reduce((v, e) => v+parseFloat(e.amount.replaceAll(".","").replaceAll(",",".")),0.0),
                    }

                    reportData["data"]["results"] ["balance"] = reportData["data"]["results"]["total"] - reportData["data"]["results"]["debts"]

                    // call modal to ask user output format of this report
                    setExportFormatSelectModal(true)
                } else {
                    // show user a modal informing about no data to export
                    setNoDataFoundModal(1)
                }
                //setExportData(reportData)
                //setExportedData("Empenho Orçamentário")
            break
            default:

            break
        }

        // generic header data
        reportData["data"]["header"]["domain"] = window.location.hostname

        const entityData = await getEntitiesApi()
        if (entityData.length > 0) {
            reportData["data"]["header"]["entity"]["name"] = entityData[0].name
            reportData["data"]["header"]["entity"]["cnpj"] = entityData[0].cnpj

        }

        if (reportLog?.status === 201) {
            reportData["data"]["header"]["report"]["created_at"] = reportLog?.data.date_export
            reportData["data"]["header"]["report"]["created_by"] = reportLog?.data.user_name
        }
        reportData["data"]["header"]["report"]["start_date"] = new Date(exportFilterParams[0]).toLocaleDateString()
        reportData["data"]["header"]["report"]["end_date"] = new Date(exportFilterParams[1]).toLocaleDateString()

        setExportData(reportData)

        //setReload(reload+1)
        setLoading(false)
    }

    /* const onExportDataReady = (resultSize) => {
        // disable loading state feedback
        setLoading(false)
        if (resultSize === 0) {
            // show user a modal informing about no data to export
            setNoDataFoundModal(1)
        }
    } */

    const onExportData = async (exportType) => {
        if (exportData !== null) {
            //exportData["convertTo"] = "pdf"
            const fileName = EXPORT_TEMPLATE_FILENAMES?.[exportTemplate]
            // call carbone service to handle export format types and convertions
            await generateReportApi(exportTemplate, exportType, exportData, fileName)
            // close format export modal
            setExportFormatSelectModal(false)
            // initialize data
            setExportData(null)
        }

        // refresh ui last log data
        fetchLastLog()
    }

    const fetchLastLog = async () => {
        const response = await getLastReportApi()
        if (response)
            setLastExportedData(response)
    }

    useEffect(() => {
        fetchLastLog()
    }, [])

    // date report rules:
    // no date selection imports all historical data
    // if a full range date is selected then get the info based on created_at
    // but the orders reports needs to be filtered by all evolutionary states based on that date
    // cadastro, autorizado, cotado... and agregated accordly... no duplicated reports!
    
    const onDateChange = (dates) => {
        const timestamps = dates.map(dateString => new Date(dateString).getTime());
        setExportFilterParams(timestamps)
    }

    const getStartOfDay = (timestamp) => {
        const currentDate = new Date(timestamp);
        const startOfDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);
        return Math.floor(startOfDay.getTime() / 1000)
    }
    
    const getEndOfDay = (timestamp) => {
        const currentDate = new Date(timestamp);
        const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);
        return Math.floor(endDate.getTime() / 1000)
    }

    return (
        <UiShell>
            <ExporterBox 
                open={exportFormatSelectModal}
                onRequestClose={() => setExportFormatSelectModal(false)}
                onExportRequest={onExportData}
            />
            {noDataFoundModal !== 0 &&
                <Modal 
                    open={noDataFoundModal}
                    modalHeading="Nenhum registro encontrado"
                    primaryButtonText="OK" 
                    onRequestSubmit={() => setNoDataFoundModal(0)} 
                    onRequestClose={() => setNoDataFoundModal(0)}
                >
                    {"Nenhum registro foi encontrado no intervalo de tempo selecionado. Para obter dados em outro intervalo de datas, ajuste as datas de início e fim"}
                </Modal>
            }
            <Section>
                <Heading className="primary">Relatórios</Heading>
                <p>Registros interativos e exportáveis de operações, cadastros e atividades realizadas no sistema.</p>
            </Section>
            <Section level={4} style={{ marginTop: '20px' }} >
                <FlexGrid style={{ paddingInline: '0px', marginTop: '20px' }}>
                    <Row style={{ marginBottom: '20px' }}>
                        <Column>
                            <DatePicker 
                                datePickerType="range"
                                locale="pt" 
                                placeholder={"dd/mm/aaaa"}
                                dateFormat={"d/m/Y"}
                                onChange={onDateChange}
                                value={exportFilterParams}
                                maxDate={today}
                            >
                                <DatePickerInput id="start-date" placeholder="dd/mm/aaaa" labelText="Início do Período" size="md" />
                                <DatePickerInput id="end-date" placeholder="dd/mm/aaaa" labelText="Fim do Período" size="md" />
                            </DatePicker>
                        </Column>
                    </Row>
                    <Row>
                        <Column>
                            <ClickableTile id="publicEntityExport" renderIcon={Download} onClick={e => exportReport(e.target.id)}>
                                Entes
                            </ClickableTile>
                        </Column>
                        <Column>
                            <ClickableTile id="linkedPublicEntityExport" renderIcon={Download} onClick={e => exportReport(e.target.id)}>
                                Entidades Vinculadas
                            </ClickableTile>
                        </Column>
                        <Column>
                            <ClickableTile id="operatorsExport" renderIcon={Download} onClick={e => exportReport(e.target.id)}>
                                Operadores Autorizados
                            </ClickableTile>
                        </Column>
                    </Row>
                    <Row style={{ marginTop: '1.5rem' }}>
                        <Column>
                            <ClickableTile id="productsExport" renderIcon={Download} onClick={e => exportReport(e.target.id)}>
                                Produtos
                            </ClickableTile>
                        </Column>
                        <Column>
                            <ClickableTile id="ordersExport" renderIcon={Download} onClick={e => exportReport(e.target.id)}>
                                Solicitações de Compra
                            </ClickableTile>
                        </Column>
                        <Column>
                            <ClickableTile id="balanceExport" renderIcon={Download} onClick={e => exportReport(e.target.id)}>
                                Empenho Orçamentário
                            </ClickableTile>
                        </Column>
                    </Row>
                    <Row style={{ marginTop: '1.5rem' }}>
                        <Column>
                            <TextInput 
                                type="text"
                                disabled={true} 
                                value={lastExportedData?.date_export}
                                labelText="Última exportação em"
                            />
                        </Column>
                        <Column>
                            <TextInput 
                                type="text"
                                disabled={true} 
                                value={lastExportedData?.exported_type}
                                labelText="Dados Exportados"
                            />
                        </Column>
                        <Column>
                            <TextInput 
                                type="text"
                                disabled={true} 
                                value={lastExportedData?.user_name}
                                labelText="Gerado por"
                            />
                        </Column>
                    </Row>
                </FlexGrid>
            </Section>
            {loading &&
                <Section style={{ marginTop: '1.5rem' }}>
                    <InlineLoading status="active" iconDescription="Carregando" description="Carregando Dados para exportação, por favor aguarde..." />
                </Section>
            }
            {/* <Section>
                <DataCRUD 
                    forceReload={reload} 
                    exportMode={true} 
                    onExportedData={onExportedData}
                    onExportDataReady={onExportDataReady}
                    noScroll={true}
                    filterParams={{ dateRange: `${getStartOfDay(exportFilterParams[0])},${getEndOfDay(exportFilterParams[1])}`}}
                    {...dataCRUDMeta} 
                />
            </Section> */}
        </UiShell>
    )
}

export default Reports